import api from "./api";

export function getPrayerListIndex(group = null) {
    if (group) {
        return api.get('/prayer-list?prayerGroup=' + group, {
            
        });
    }

    return api.get('/prayer-list', {
        
    })
}

export function getPrayerGroupsAndList() {
    return api.get('/prayer-groups-and-list', {
       
    })
}

export function createPrayerGroup(groupName) {
    return api.post('/prayer-groups', {
        group_name: groupName,
    }, {
        
    })
}

export function createPrayerRequest(group, subject, matter) {
    return api.post('/prayer-list', {
        prayer_group_id: group,
        subject: subject,
        description: matter,
    }, {
        
    })
}

export function editPrayerRequest(id, group, subject, matter) {
    return api.patch(`/prayer-list/${id}`, {
        prayer_group_id: group,
        subject: subject,
        description: matter,
    }, {
        
    })
}

export function deletePrayerRequest(id) {
    return api.delete(`/prayer-list/${id}`, {
        
    })
}

export function deletePrayerGroup(id) {
  return api.delete(`/prayer-groups/${id}`, {});
}