import { bookNames, chapterCounts } from "../../bibleMeta";
// import "./BibleBox.css";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import { addHighlight, deleteHighlight } from "../../services/highlightService";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { useEffect } from "react";
import ChapterTitle from "./chapterTitle";
import { LST_SLCTD_VERSE_LENGTH } from '../../utils/bibleconstants';
import { useMediaQuery } from "@material-ui/core";
import projector from "../../assets/images/projector.png";
import {
  Box,
  Popover,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  FileCopyOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import copy from "copy-to-clipboard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { EffectCreative, Navigation } from "swiper/modules";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";

export default function BibleBox({
  user,
  userIsLoading,
  bookNo,
  oldBookNo,
  chapterNo,
  oldChapterNo,
  title,
  verseNo,
  verseCount,
  globalVersions,
  bibleData,
  chapterNotes,
  setNoteVerseNo,
  chapterHighlights,
  setChapterHighlights,
  changeChapter,
  changeBook,
  changeVerse,
  handleToggle,
  errorMessage,
  lastSelectedVerses,
  setLastSelectedVerses,
  setVerseViewOpen,
  projectSlctVerse,
  setProjectSlctVerse,
  setBibleBoxProjector,
  bibleBoxProjector,
}) {
  const renderHeaders = () => {
    const headers = [];
    globalVersions.forEach((value, index) => {
      headers.push(
        <h2 key={Number(index) + 1}>
          {bookNames[value]
            ? bookNames[value][Number(oldBookNo - 1)]
            : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
          &nbsp;
          {oldChapterNo}
        </h2>
      );
    });
    return headers;
  };

  const ifMobile = useMediaQuery("(max-width: 580px)");
  const defaultFontSize = 18;
  const updatedVersions = localStorage.getItem("updatedVersions")
    ? localStorage.getItem("updatedVersions").split(",")
    : [];
  
  const openVerseView = index => {
    setVerseViewOpen(true);
    setBibleBoxProjector(true);
    setProjectSlctVerse(index);
  };
  
  const renderVerseVersions = verse => {
    const verseVersions = [];
    globalVersions.forEach(single => {
      let verseStyle = {};
      if (chapterHighlights[verse]) {
        verseStyle = {
          backgroundColor: chapterHighlights[verse],
        };
      }
      if (bibleData[single]) {
        const fontsize =
          ifMobile && updatedVersions.length > 1
            ? defaultFontSize
            : Number(localStorage.getItem("fontSize")) || 15;
        verseVersions.push(
          <div key={single} className={"column version version-" + single}>
            <span className="verse-number">{verse}.</span>
            <span
              className={`verse-text`}
              style={{ ...verseStyle, fontSize: `${fontsize}px` }}
            >
              {bibleData[single][verse]}
            </span>
          </div>
        );
      }
    });

    return verseVersions;
  };

  const handleAddNoteClick = verse => {
    setNoteVerseNo(verse);
    handleToggle("noteAddBox");
  };

  const handleViewNoteClick = verse => {
    setNoteVerseNo(verse);
    handleToggle("notes");
  };

  const handleHighlightClick = (verse, color) => {
    addHighlight(oldBookNo, oldChapterNo, verse, "english_nkjv", color).then(
      response => {
        const newHighlights = { ...chapterHighlights };
        newHighlights[verse] = color;
        setChapterHighlights(newHighlights);
      }
    );
  };

  const handleHighlightRemoveClick = verse => {
    deleteHighlight(oldBookNo, oldChapterNo, verse).then(() => {
      let newHighlights = {};
      for (const [key, value] of Object.entries(chapterHighlights)) {
        if (String(key) !== String(verse)) {
          newHighlights[key] = value;
        } else {
        }
      }
      setChapterHighlights(newHighlights);
    });
  };

  const verseCopy = verse => {
    let copied = false;
    globalVersions.forEach(single => {
      if (bibleData[single]) {
        const book = bookNames[single][bookNo - 1];
        const verseCopied = `${book} ${chapterNo}:${verse}. ${bibleData[single][verse]}`;
        copy(verseCopied);
        copied = true;
      }
    });
    if (copied) {
      toast.success("Copied to Clipboard", {
        position: "top-center",
        className: "toast-middle",
      });
    }
  };
  const [lastSelected, setLastSelected] = useState({
    book: localStorage.getItem("lastAddedBook"),
    chapter: localStorage.getItem("lastAddedChapter"),
    verse: localStorage.getItem("lastAddedVerse"),
  });
  useEffect(() => {
    localStorage.setItem("lastAddedBook", lastSelected.book);
    localStorage.setItem("lastAddedChapter", lastSelected.chapter);
    localStorage.setItem("lastAddedVerse", lastSelected.verse);
  }, [lastSelected]);
  const addVerseToLastReadVerse = verse => {
    const existingLastSlctedVerses = [...lastSelectedVerses];
    let exist = false;
    for (let i = 0; i < existingLastSlctedVerses.length; i++) {
      if (
        existingLastSlctedVerses[i].book === bookNo &&
        existingLastSlctedVerses[i].chapter === chapterNo &&
        existingLastSlctedVerses[i].verse === verse
      ) {
        exist = true;
        break;
      }
    }
    if (exist) {
      toast.error("Already Selected", {
        position: "top-center",
        className: "toast-middle",
      });
    }
    if (!exist) {
      if (existingLastSlctedVerses.length >= LST_SLCTD_VERSE_LENGTH) {
        existingLastSlctedVerses.shift();
      }

      existingLastSlctedVerses.push({
        book: bookNo,
        chapter: chapterNo,
        verse: verse,
      });
      toast.success("Added to Selected Verses", {
        position: "top-center",
        className: "toast-middle",
      });
    }
    setLastSelectedVerses(existingLastSlctedVerses);
    localStorage.setItem(
      "lastSelectedVerses",
      JSON.stringify(existingLastSlctedVerses)
    );
  };

  const renderVerseOptions = verse => {
    return (
      <div className="verse-options">
        <div className="primary-options">
          {chapterNotes[verse] && chapterNotes[verse].length !== 0 && (
            <>
              <button
                className="note-button"
                onClick={() => {
                  handleViewNoteClick(verse);
                }}
                title="View Note"
              >
                <MessageOutlinedIcon />
              </button>
            </>
          )}
        </div>
        <div className="secondary-options">
          <div className="highlight-buttons">
            <button
              onClick={() => {
                handleHighlightClick(verse, "#D5F4CB");
              }}
              className="highlight-green-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#D2E8FF");
              }}
              className="highlight-blue-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#F8D1DC");
              }}
              className="highlight-rose-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#FFEEC9");
              }}
              className="highlight-gold-button"
            ></button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                handleHighlightRemoveClick(verse);
              }}
              className="highlight-none-button"
            >
              <NotInterestedIcon />
            </button>
            <button
              onClick={() => {
                handleAddNoteClick(verse);
              }}
              title="Add Note"
              className="addbutton"
            >
              <NoteAddOutlinedIcon />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                verseCopy(verse);
              }}
              title="Copy Verse"
            >
              <FileCopyOutlined />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                addVerseToLastReadVerse(verse);
              }}
              title={
                lastSelectedVerses.some(
                  item =>
                    item.book === bookNo &&
                    item.chapter === chapterNo &&
                    item.verse === verse
                )
                  ? "Already Selected"
                  : "Add to Selected Verses"
              }
            >
              <CollectionsBookmarkIcon />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                openVerseView(verse);
              }}
              title="Show on Projector"
            >
              <img src={projector} alt="projector" className="projectorIcon" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderVerses = () => {
    const verses = [];
    for (let i = 1; i <= verseCount; i++) {
      verses.push(
        <div className="bible-verse" key={i} id={"bible-verse-" + i}>
          <div className="versions">{renderVerseVersions(i)}</div>
          {user && renderVerseOptions(i)}
        </div>
      );
    }
    return verses;
  };

  useEffect(() => {
    const bibleElement = document.querySelector(".bible-box .box-content");
    bibleElement.addEventListener("mouseup", event => {
      const searchInputElement = document.querySelector(
        ".search-box .search-term-input"
      );
      if (!bibleElement || !searchInputElement) {
        return;
      }
      let selection = "";
      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.selection) {
        selection = document.selection.createRange();
      }
      searchInputElement.value = selection;
    });
  }, []);

  const handleSlideChange = async swipe => {
    const { activeIndex, slides } = swipe;
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    if (swipe.swipeDirection === "next") {
      if (activeIndex === slides.length - 1) {
        // If last chapter move to 1st chapter.
        if (chapterCounts[Number(oldBookNo) - 1] < Number(chapterNo) + 1) {
          if (names.length < Number(oldBookNo) + 1) {
            await changeBook(1);
            await changeChapter(1);
            await changeVerse(1);
          } else {
            await changeBook(Number(oldBookNo) + 1);
            await changeChapter(1);
            await changeVerse(1);
          }
        } else {
          await changeChapter(Number(chapterNo) + 1);
          await changeVerse(1);
        }
      }
    }

    if (swipe.swipeDirection === "prev") {
      if (activeIndex === 0) {
        // Add a check to prevent setting chapterNo below 1
        if (Number(chapterNo) > 1) {
          await changeChapter(Number(chapterNo) - 1);
          await changeVerse(1);
        }
      }
    }
  };

  const handleNextChapter = async () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    if (chapterCounts[Number(oldBookNo) - 1] < Number(chapterNo) + 1) {
      if (names.length < Number(oldBookNo) + 1) {
        await changeBook(1);
        await changeChapter(1);
        await changeVerse(1);
      } else {
        await changeBook(Number(oldBookNo) + 1);
        await changeChapter(1);
        await changeVerse(1);
      }
    } else {
      await changeChapter(Number(chapterNo) + 1);
      await changeVerse(1);
    }
  };

  const handlePrevChapter = async () => {
    if (Number(chapterNo) > 1) {
      await changeChapter(Number(chapterNo) - 1);
      await changeVerse(1);
    }
  };

  return (
    <div className="box bible-box">
      <Toaster />
      <Stack direction="row" alignItems="center">
        <div className="box-header" style={{ width: "100%" }}>
          {renderHeaders()}
        </div>
      </Stack>

      <ChapterTitle book={bookNo} chapter={chapterNo} vers={globalVersions} />
      <div className="swiper">
        <div className="box-content">
          <Swiper
            // grabCursor={true}
            modules={[EffectCreative, Navigation]}
            className="mySwiper"
            slidesPerView={1}
            onSlideChange={handleSlideChange}
            // style={{ height: "100%" }}
            loop={true}
            effect="creative"
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            touchStartPreventDefault={false}
          >
            <SwiperSlide>
              {errorMessage ? (
                <div className="error-message-container ">
                  <div className="error-message">
                    <div style={{ flex: 1 }}>
                      <strong>Error!</strong> {errorMessage}
                    </div>
                  </div>
                </div>
              ) : (
                renderVerses()
              )}
            </SwiperSlide>
            <SwiperSlide>
              {errorMessage ? (
                <div className="error-message-container ">
                  <div className="error-message">
                    <div style={{ flex: 1 }}>
                      <strong>Error!</strong> {errorMessage}
                    </div>
                  </div>
                </div>
              ) : (
                renderVerses()
              )}
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper-button-prev" onClick={handlePrevChapter}></div>
        <div className="swiper-button-next" onClick={handleNextChapter}></div>
      </div>
    </div>
  );
}
