export const chapterCounts = [
  50, 40, 27, 36, 34, 24, 21, 4, 31, 24, 22, 25, 29, 36, 10, 13, 10, 42, 150,
  31, 12, 8, 66, 52, 5, 48, 12, 14, 3, 9, 1, 4, 7, 3, 3, 3, 2, 14, 4, 28, 16,
  24, 21, 28, 16, 16, 13, 6, 6, 4, 4, 5, 3, 6, 4, 3, 1, 13, 5, 5, 3, 5, 1, 1, 1,
  22,
];

export const bookNames = {
  english_nkjv: [
    "Genesis",
    "Exodus",
    "Leviticus",
    "Numbers",
    "Deuteronomy",
    "Joshua",
    "Judges",
    "Ruth",
    "1 Samuel",
    "2 Samuel",
    "1 Kings",
    "2 Kings",
    "1 Chronicles",
    "2 Chronicles",
    "Ezra",
    "Nehemiah",
    "Esther",
    "Job",
    "Psalms",
    "Proverbs",
    "Ecclesiastes",
    "Song of Solomon",
    "Isaiah",
    "Jeremiah",
    "Lamentations",
    "Ezekiel",
    "Daniel",
    "Hosea",
    "Joel",
    "Amos",
    "Obadiah",
    "Jonah",
    "Micah",
    "Nahum",
    "Habakkuk",
    "Zephaniah",
    "Haggai",
    "Zechariah",
    "Malachi",
    "Matthew",
    "Mark",
    "Luke",
    "John",
    "Acts",
    "Romans",
    "1 Corinthians",
    "2 Corinthians",
    "Galatians",
    "Ephesians",
    "Philippians",
    "Colossians",
    "1 Thessalonians",
    "2 Thessalonians",
    "1 Timothy",
    "2 Timothy",
    "Titus",
    "Philemon",
    "Hebrews",
    "James",
    "1 Peter",
    "2 Peter",
    "1 John",
    "2 John",
    "3 John",
    "Jude",
    "Revelation",
  ],
  malayalam: [
    "ഉല്പത്തി",
    "പുറപ്പാടു്",
    "ലേവ്യപുസ്തകം",
    "സംഖ്യാപുസ്തകം",
    "ആവർത്തനം",
    "യോശുവ",
    "ന്യായാധിപന്മാർ",
    "രൂത്ത്",
    "1 ശമൂവേൽ",
    "2 ശമൂവേൽ",
    "1 രാജാക്കന്മാർ",
    "2 രാജാക്കന്മാർ",
    "1 ദിനവൃത്താന്തം",
    "2 ദിനവൃത്താന്തം",
    "എസ്രാ",
    "നെഹെമ്യാവു",
    "എസ്ഥേർ",
    "ഇയ്യോബ്",
    "സങ്കീർത്തനങ്ങൾ",
    "സദൃശ്യവാക്യങ്ങൾ",
    "സഭാപ്രസംഗി",
    "ഉത്തമഗീതം",
    "യെശയ്യാ",
    "യിരേമ്യാവു",
    "വിലാപങ്ങൾ",
    "യേഹേസ്കേൽ",
    "ദാനീയേൽ",
    "ഹോശേയ",
    "യോവേൽ",
    "ആമോസ്",
    "ഓബദ്യാവു",
    "യോനാ",
    "മീഖാ",
    "നഹൂം",
    "ഹബക്കൂക്‍",
    "സെഫന്യാവു",
    "ഹഗ്ഗായി",
    "സെഖർയ്യാവു",
    "മലാഖി",
    "മത്തായി",
    "മർക്കൊസ്",
    "ലൂക്കോസ്",
    "യോഹന്നാൻ",
    "പ്രവൃത്തികൾ ",
    "റോമർ",
    "1 കൊരിന്ത്യർ",
    "2 കൊരിന്ത്യർ",
    "ഗലാത്യർ ",
    "എഫെസ്യർ ",
    "ഫിലിപ്പിയർ ",
    "കൊലൊസ്സ്യർ ",
    "1 തെസ്സലൊനീക്യർ",
    "2 തെസ്സലൊനീക്യർ",
    "1 തിമൊഥെയൊസ്",
    "2 തിമൊഥെയൊസ്",
    "തീത്തൊസ്",
    "ഫിലേമോൻ",
    "എബ്രായർ",
    "യാക്കോബ്",
    "1 പത്രൊസ്",
    "2 പത്രൊസ്",
    "1 യോഹന്നാൻ",
    "2 യോഹന്നാൻ",
    "3 യോഹന്നാൻ",
    "യൂദാ ",
    "വെളിപ്പാട്",
  ],
  tamil: [
    "ஆதியாகமம்",
    "யாத்திராகமம்",
    "லேவியராகமம்",
    " எண்ணாகமம்",
    " உபாகமம்",
    " யோசுவா",
    " நியாயாதிபதிகள்",
    " ரூத்",
    " 1 சாமுவேல்",
    " 2 சாமுவேல்",
    " 1 இராஜாக்கள்",
    " 2 இராஜாக்கள்",
    " 1 நாளாகமம்",
    " 2 நாளாகமம்",
    " எஸ்றா",
    " நெகேமியா",
    " எஸ்தர்",
    " யோபு",
    " சங்கீதம்",
    " நீதிமொழிகள்",
    " பிரசங்கி",
    " உன்னதப்பாட்டு",
    " ஏசாயா",
    " எரேமியா புலம்பல்",
    " புலம்பல் ",
    " எசேக்கியேல்",
    " தானியேல்",
    " ஓசியா",
    " யோவேல்",
    " ஆமோஸ்",
    " ஒபதியா",
    " யோனா",
    " மீகா",
    " நாகூம்",
    " ஆபகூக்",
    " செப்பனியா",
    " ஆகாய்",
    " சகரியா",
    " மல்கியா",
    " மத்தேயு",
    " மாற்கு",
    " லூக்கா",
    " யோவான்",
    " நடபடிகள",
    " ரோமர்",
    " 1 கொரிந்தியர்",
    " 2 கொரிந்தியர்",
    " கலாத்தியர்",
    " எபேசியர்",
    " பிலிப்பியர்",
    " கொலோசெயர்",
    " 1 தெசலோனிக்கேயர்",
    " 2 தெசலோனிக்கேயர்",
    " 1 தீமோத்தேயு",
    " 2 தீமோத்தேயு",
    " தீத்து",
    "பிலேமோன்",
    " எபிரெயர்",
    " யாக்கோபு",
    " 1 பேதுரு",
    " 2 பேதுரு",
    " 1 யோவான்",
    " 2 யோவான்",
    " 3 யோவான்",
    " யூதா",
    " வெளிப்படுத்தின விசேஷம்",
  ],
  gujarati: [
    "ઊત્પત્તિ",
    "નિર્ગમન",
    "લેવીય",
    "ગણના",
    "પુનર્નિયમ",
    "યહોશુઆ",
    "ન્યાયાધીશો",
    "રૂત",
    "1 શમુએલ",
    "2 શમુએલ",
    "1 રાજઓ",
    "2 રાજઓ",
    "1 કાળવ્રત્તાંત",
    "2 કાળવ્રત્તાંત",
    "એઝરા",
    "નહેમ્યા",
    "એસ્તેર",
    "અયૂબ",
    "ગીતશાસ્ત્ર",
    "નીતિવચનો",
    "સભાશિક્ષક",
    "સભાશિક્ષક",
    "યશાયા",
    "ચર્મિયા",
    "યર્મિયાનો વિલાપ",
    "હઝકિયેલ",
    "દારિયેલ",
    "હોશિયા",
    "યોએલ",
    "આમોસ",
    "ઓબાધા",
    "યૂના",
    "મીખાહ",
    "નાહૂમ",
    "હબાક્કુક",
    "સફન્યા",
    "હાગ્ગાચ",
    "ઝખાર્યા",
    "માલાખી",
    "માથ્થી",
    "માર્ક",
    "લૂક",
    "યોહાન",
    "ક્રત્યો",
    "રોમનોને પત્ર",
    "1 કરિંથીઓને",
    "2 કરિંથીઓને",
    "ગલાતીઓને પત્ર",
    "એફેસીઓને પત્ર",
    "ફિલિપ્પીઓને પત્ર",
    "કલોસ્સીઓને પત્ર",
    "1 થેસ્સલોનિકીઓને",
    "2 થેસ્સલોનિકીઓને",
    "1 તિમોથીને",
    "2 તિમોથીને",
    "તિતસનં પત્ર",
    "ફિલેમોને પત્ર",
    "હિબ્રૂઓને પત્ર",
    "યાકૂબનો",
    "1 પિતરનો પત્ર",
    "2 પિતરનો પત્ર",
    "1 યોહાનનો પત્ર",
    "2 યોહાનનો પત્ર",
    "3 યોહાનનો પત્ર",
    "યહૂદાનો પત્ર",
    "પ્રકટીકરણ",
  ],
  kannada: [
    "ಆದಿಕಾಂಡ",
    "ವಿಮೋಚನಕಾಂಡ",
    "ಯಾಜಕಕಾಂಡ",
    "ಅರಣ್ಯಕಾಂಡ",
    "ಧರ್ಮೋಪದೇಶಕಾಂಡ",
    "ಯೆಹೋಶುವ",
    "ನ್ಯಾಯಸ್ಥಾಪಕರು",
    "ರೂತಳು",
    "1 ಸಮುವೇಲನು",
    "2 ಸಮುವೇಲನು",
    "1 ಅರಸುಗಳು",
    "2 ಅರಸುಗಳು",
    "1 ಪೂರ್ವಕಾಲವೃತ್ತಾ",
    "2 ಪೂರ್ವಕಾಲವೃತ್ತಾ",
    "ಎಜ್ರನು",
    "ನೆಹೆಮಿಯ",
    "ಎಸ್ತೇರಳು",
    "ಯೋಬನು",
    "ಕೀರ್ತನೆಗಳು",
    "ಙ್ಞಾನೋಕ್ತಿಗಳು",
    "ಪ್ರಸಂಗಿ",
    "ಪರಮ ಗೀತ",
    "ಯೆಶಾಯ",
    "ಯೆರೆಮಿಯ",
    "ಪ್ರಲಾಪಗಳು",
    "ಯೆಹೆಜ್ಕೇಲನು",
    "ದಾನಿಯೇಲನು",
    "ಹೋಶೇ",
    "ಯೋವೇಲ",
    "ಆಮೋಸ",
    "ಓಬದ್ಯ",
    "ಯೋನ",
    "ಮಿಕ",
    "ನಹೂಮ",
    "ಹಬಕ್ಕೂಕ್ಕ",
    "ಚೆಫನ್ಯ",
    "ಹಗ್ಗಾಯ",
    "ಜೆಕರ್ಯ",
    "ಮಲಾಕಿಯ",
    "ಮತ್ತಾಯನು",
    "ಮಾರ್ಕನು",
    "ಲೂಕನು",
    "ಯೋಹಾನನು",
    "ಕೃತ್ಯಗ",
    "ರೋಮಾಪುರದವರಿಗೆ",
    "1 ಕೊರಿಂಥದವರಿಗೆ",
    "2 ಕೊರಿಂಥದವರಿಗೆ",
    "ಗಲಾತ್ಯದವರಿಗೆ",
    "ಎಫೆಸದವರಿಗೆ",
    "ಫಿಲಿಪ್ಪಿಯವರಿಗೆ",
    "ಕೊಲೊಸ್ಸೆಯವರಿಗೆ",
    "1 ಥೆಸಲೊನೀಕದವರಿಗೆ",
    "2 ಥೆಸಲೊನೀಕದವರಿಗೆ",
    "1 ತಿಮೊಥೆಯನಿಗೆ",
    "2 ತಿಮೊಥೆಯನಿಗೆ",
    "ತೀತನಿಗೆ",
    "ಫಿಲೆಮೋನನಿಗೆ",
    "ಇಬ್ರಿಯರಿಗೆ",
    "ಯಾಕೋಬನು",
    "1 ಪೇತ್ರನು",
    "2 ಪೇತ್ರನು",
    "1 ಯೋಹಾನನು",
    "2 ಯೋಹಾನನು",
    "3 ಯೋಹಾನನು",
    "ಯೂದನು",
    "ಪ್ರಕಟನೆ",
  ],
  hindi: [
    "उत्पाति",
    "निर्गमन",
    "लैव्यव्यवस्थ",
    "गिनती",
    "व्यवस्थाविवरण",
    "यहोशू",
    "न्यायियों",
    "रुत",
    "1 शमूएल",
    "2 शमूएल",
    "1 राजाओं",
    "2 राजाओं",
    "1 इतिहास",
    "2 इतिहास",
    "एज्रा",
    "नहेम्याह",
    "एस्तेर",
    "अय्यूब",
    "भजन संहिता",
    "नीतिबचन",
    "सभोपदेशक",
    "श्रेष्ठगीत",
    "यशायाह",
    "यिर्मयाह",
    "विलापगेत",
    "यहेजकेल",
    "दानिय्यल",
    "होशे",
    "योएल",
    "आमोस",
    "ओबधाह",
    "योना",
    "मीका",
    "नहूम",
    "हबक्कूक",
    "सपन्याह",
    "हाग्गै",
    "जकर्याह",
    "मलाकी",
    "मत्ती",
    "मरकुस",
    "लूका",
    "युहत्रा",
    "कामों",
    "रोमियों",
    "1 कुरिन्थियों",
    "2 कुरिन्थियों",
    "गलतियों",
    "इफिसियों",
    "फिलिप्पियों",
    "कुलुस्सियों",
    "1 थिस्सलुनीकियों",
    "2 थिस्सलुनीकियों",
    "1 तीमुाथैयुस",
    "2 तीमुाथैयुस",
    "तीतुस",
    "फिलेमोन",
    "इब्रानियों",
    "याकूब",
    "1 पतरस",
    "2 पतरस",
    "1 युहत्रा",
    "2 युहत्रा",
    "3 युहत्रा",
    "यहूदा",
    "प्रकाशितवाक्य",
  ],
  bengali: [
    "আদিপুস্তক",
    "যাত্রাপুস্তক",
    "লেবীয় পুস্তক",
    "গণনা পুস্তক",
    "দ্বিতীয় বিবরণ",
    "যোশুয়া",
    "বিচারকচরিত",
    "রুথ",
    "1 সামুয়েল",
    "2 সামুয়েল",
    "1 রাজাবলি",
    "2 রাজাবলি",
    "1 বংশাবলি",
    "2 বংশাবলি",
    "এজরা",
    "নেহেমিয়া",
    "এস্থার",
    "যোব",
    "সামসঙ্গীত",
    "প্রবচন",
    "উপদেশক",
    "পরম গীত",
    "ইসাইয়া",
    "যেরেমিয়া",
    "বিলাপ-গাথা",
    "এজেকিয়েল",
    "দানিয়েল",
    "হোসেয়া",
    "যোয়েল",
    "আমোস",
    "ওবাদিয়া",
    "যোনা",
    "মিখা",
    "নাহুম",
    "হাবাকুক",
    "জেফানিয়া",
    "হগয়",
    "জাখারিয়া",
    "মালাখি",
    "মথি",
    "মার্ক",
    "লুক",
    "যোহন",
    "पশিষ্যচরিত",
    "রোমীয়",
    "1 করিন্থীয়",
    "2 করিন্থীয়",
    "গালাতীয়",
    "এফেসীয়",
    "ফিলিপ্পীয়",
    "কলসীয়",
    "1 থেসালোনিকীয়",
    "2 থেসালোনিকীয়",
    "1 তিমথি",
    "2 তিমথি",
    "তীত",
    "ফিলেমন",
    "হিব্রুদের কাছে পত্র",
    "যাকোবের পত্র",
    "পিতরের ১ম পত্র",
    "পিতরের ২য় পত্র",
    "যোহনের ১ম পত",
    "যোহনের ২য় পত্",
    "যোহনের ৩য় পত্",
    "যুদের পত্র",
    "पপ্রত্যাদেশ",
  ],
  telugu: [
    "ఆదికాండము",
    "నిర్గమకాండము",
    "లేవీయకాండము",
    "సంఖ్యాకాండము",
    "ద్వితీయోపదేశకాండమ",
    "యెహొషువ",
    "న్యాయాధిపతులు",
    "రూతు",
    "సమూయేలు మొదటి గ్రంథము",
    "సమూయేలు రెండవ గ్రంథము",
    "రాజులు మొదటి గ్రంథము",
    "రాజులు రెండవ గ్రంథము",
    "దినవృత్తాంతములు మొదటి గ్రంథము",
    "దినవృత్తాంతములు రెండవ గ్రంథము",
    "ఎజ్రా",
    "నెహెమ్యా",
    "ఎస్తేరు",
    "యోబు గ్రంథము",
    "కీర్తనల గ్రంథము",
    "సామెతలు",
    "ప్రసంగి",
    "పరమగీతము",
    "యెషయా గ్రంథము",
    "యిర్మీయా",
    "విలాపవాక్యములు",
    "యెహెజ్కేలు",
    "దానియేలు",
    "హొషేయ",
    "యోవేలు",
    "ఆమోసు",
    "ఓబద్యా",
    "యోనా",
    "మీకా",
    "నహూము",
    "హబక్కూకు",
    "జెఫన్యా",
    "హగ్గయి",
    "జెకర్యా",
    "మలాకీ",
    "మత్తయి సువార్త",
    "మార్కు సువార్త ",
    "లూకా సువార్త",
    "యోహాను సువార్త",
    "అపొస్తలుల కార్యములు",
    "రోమీయులకు",
    "1 కొరింథీయులకు",
    "2 కొరింథీయులకు",
    "గలతీయులకు",
    "ఎఫెసీయులకు",
    "ఫిలిప్పీయులకు",
    "కొలొస్సయులకు",
    "1 థెస్సలొనీకయులకు",
    "2 థెస్సలొనీకయులకు",
    "1 తిమోతికి",
    "2 తిమోతికి",
    "తీతుకు",
    "ఫిలేమోనుకు",
    "హెబ్రీయులకు",
    "యాకోబు",
    "1 పేతురు",
    "2 పేతురు",
    "1 యోహాను",
    "2 యోహాను",
    "3 యోహాను",
    "యూదా",
    "ప్రకటన గ్రంథము",
  ],
};

export const bookLanguage = [
  { english_nkjv: { old: "Old Testament", new: "New Testament" } },
  { malayalam: { old: "പഴയ നിയമം", new: "പുതിയ നിയമം" } },
  { tamil: { old: "பழைய ஏற்பாடு", new: "புதிய ஏற்பாடு" } },
  { gujarati: { old: "ઓલ્ડ ટેસ્ટામેન્ટ", new: "ન્યૂ વસિયતનામું" } },
  { kannada: { old: "ಹಳೆಯ ಒಡಂಬಡಿಕೆ", new: "ಹೊಸ ಒಡಂಬಡಿಕೆಯು" } },
  { hindi: { old: "पुराना नियम", new: "नया नियम" } },
  { bengali: { old: "পুরানো ইচ্ছাপত্র", new: "নববিধান" } },
  { telugu: { old: "పాత నిబంధన", new: "కొత్త నిబంధన" } },
];
